* {
  box-sizing: border-box;
}

body {
  font-size: 16px;
}

input {
  font-size: 1rem;
}

.page-view {
  margin: 0 10px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

legend {
  font-size: 2rem;
  font-weight: bold;
}

button {
  /* width: calc(100% - 40px); */
  /* height: calc(100% - 40px); */
  margin: 20px;
  font-size: 2rem;
  padding: 12px 24px;
  border: 10px #fff solid;
  background: #eee;
  border-radius: 300px;
  box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.75);
  font-family: sans-serif;
  outline: none;
  border: 10px solid #13544e;
  background-color: #063531;
  color: #f9ffff;
  transition: background-color 150ms ease-out, border-color 150ms ease-out, transform 150ms ease-out, box-shadow 150ms ease-out, color 150ms ease-out;
}
button.isPressed,
button:not(.isToggle):focus,
button:not(.isToggle):disabled,
button:not(.isToggle):active {
  transform: translate(8px, 8px);
  box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.75);
}
button:disabled {
  border-color: #435655;
  background-color: #262b2b;
  color: #dedede;
}

.start-score {
  background-color: #992266;
  border-color: #881155;
}
.submit-score {
  background-color: #3399aa;
  border-color: #228899;
}
.join-game,
.fetch-score {
  background-color: #3399aa;
  border-color: #228899;
}
.lock-answers {
  background-color: #669922;
  border-color: #558811;
}
.view-members {
  background-color: #2c0635;
  border-color: #411354;
}
.view-answers {
  background-color: #062435;
  border-color: #132a54;
}
.score-sort {
  background-color: #143506;
  border-color: #2c5413;
}
.delete-all {

}

[name="loadTeam"],
[name="createTeam"] {
  margin-top: 48px;
  display: grid;
  grid-row-gap: 6px;
}
[name="loadTeam"] {
  margin-top: 66px;
  
}

input[type="radio"] {
  position: absolute;
  opacity: 0.0001;
}

input[type="radio"] + label {
  border: 2px dashed transparent;
  padding: 6px 5px 6px 6px;
  border-radius: 30px;
  transition: border-color 300ms ease-out;
}
input[type="radio"][value="1"] + .score-label {
  color: #669922;
  padding: 6px 11px 6px 10px;
  margin-left: -10px;
}
input[type="radio"][value="0.5"] + .score-label {
    padding: 6px 8px 6px 10px;
}
input[type="radio"]:active + label,
input[type="radio"]:hover + label,
input[type="radio"]:focus + label {
  border-color: #cccccc;
}
input[type="radio"]:checked + label {
  border-color: #333333;
}
/*
input[type="radio"] + label {
  position: relative;
  color: #ffffef;
  display: block;
  width: 100%;
  padding: 20px 20px 20px 45px;
  background-color: #2277bb;
  border: #1166aa 6px solid;
  margin-bottom: 6px;
  border-radius: 12px;
  opacity: 1;
  transition: background-color 300ms ease-out, border-color 300ms ease-out;
}
input[type="radio"]:disabled + label {
  opacity: 0.3;
}

input[type="radio"] + label:after,
input[type="radio"] + label:before {
  content: " ";
  position: absolute;
  border-radius: 20px;
}
input[type="radio"] + label:before {
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: #1166aa 3px solid;
  left: 10px;
  top: 15px;
}

input[type="radio"] + label:after {
  opacity: 0;
  background-color: #55dd88;
  border: none;
  height: 16px;
  width: 16px;
  left: 15px;
  top: 20px;
  transition: opacity 300ms ease-out;
}

input[type="radio"] + label:active,
input[type="radio"]:active + label,
input[type="radio"] + label:hover,
input[type="radio"]:hover + label,
input[type="radio"] + label:focus,
input[type="radio"]:focus + label {
  background-color: #005599;
  border-color: #004488;
}

input[type="radio"]:checked + label {

}

input[type="radio"]:checked + label:after {
  opacity: 1;
}

input[type="radio"]#vote0 + label {
  background-color: #669922;
}
input[type="radio"]#vote0 + label,
input[type="radio"]#vote0 + label:before {
  border-color: #558811;
}
input[type="radio"]#vote0 + label:focus,
input[type="radio"]#vote0:focus + label,
input[type="radio"]#vote0 + label:hover,
input[type="radio"]#vote0:hover + label,
input[type="radio"]#vote0 + label:active,
input[type="radio"]#vote0:active + label {
  background-color: #447700;
}
input[type="radio"]#vote0 + label:focus,
input[type="radio"]#vote0:focus + label:before,
input[type="radio"]#vote0:focus + label,
input[type="radio"]#vote0 + label:focus:before,
input[type="radio"]#vote0 + label:hover,
input[type="radio"]#vote0:hover + label:before,
input[type="radio"]#vote0:hover + label,
input[type="radio"]#vote0 + label:hover:before,
input[type="radio"]#vote0 + label:active,
input[type="radio"]#vote0:active + label:before,
input[type="radio"]#vote0:active + label,
input[type="radio"]#vote0 + label:active:before {
  border-color: #336600;
}
input[type="radio"]#vote0 + label:after {
  background-color: #88bb44;
}
input[type="radio"]#vote1 + label {
  background-color: #992266;
}
input[type="radio"]#vote1 + label,
input[type="radio"]#vote1 + label:before {
  border-color: #881155;
}
input[type="radio"]#vote1 + label:focus,
input[type="radio"]#vote1:focus + label,
input[type="radio"]#vote1 + label:hover,
input[type="radio"]#vote1:hover + label,
input[type="radio"]#vote1 + label:active,
input[type="radio"]#vote1:active + label {
  background-color: #770044;
}
input[type="radio"]#vote1 + label:focus,
input[type="radio"]#vote1:focus + label:before,
input[type="radio"]#vote1:focus + label,
input[type="radio"]#vote1 + label:focus:before,
input[type="radio"]#vote1 + label:hover,
input[type="radio"]#vote1:hover + label:before,
input[type="radio"]#vote1:hover + label,
input[type="radio"]#vote1 + label:hover:before,
input[type="radio"]#vote1 + label:active,
input[type="radio"]#vote1:active + label:before,
input[type="radio"]#vote1:active + label,
input[type="radio"]#vote1 + label:active:before {
  border-color: #660033;
}
input[type="radio"]#vote1 + label:after {
  background-color: #bb4488;
}
input[type="radio"]#vote2 + label {
  background-color: #bb9922;
}
input[type="radio"]#vote2 + label,
input[type="radio"]#vote2 + label:before {
  border-color: #aa8811;
}
input[type="radio"]#vote2 + label:focus,
input[type="radio"]#vote2:focus + label,
input[type="radio"]#vote2 + label:hover,
input[type="radio"]#vote2:hover + label,
input[type="radio"]#vote2 + label:active,
input[type="radio"]#vote2:active + label {
  background-color: #997700;
}
input[type="radio"]#vote2 + label:focus,
input[type="radio"]#vote2:focus + label:before,
input[type="radio"]#vote2:focus + label,
input[type="radio"]#vote2 + label:focus:before,
input[type="radio"]#vote2 + label:hover,
input[type="radio"]#vote2:hover + label:before,
input[type="radio"]#vote2:hover + label,
input[type="radio"]#vote2 + label:hover:before,
input[type="radio"]#vote2 + label:active,
input[type="radio"]#vote2:active + label:before,
input[type="radio"]#vote2:active + label,
input[type="radio"]#vote2 + label:active:before {
  border-color: #886600;
}
input[type="radio"]#vote2 + label:after {
  background-color: #ddbb44;
}
input[type="radio"]#vote3 + label {
  background-color: #3399aa;
}
input[type="radio"]#vote3 + label,
input[type="radio"]#vote3 + label:before {
  border-color: #228899;
}
input[type="radio"]#vote3 + label:focus,
input[type="radio"]#vote3:focus + label,
input[type="radio"]#vote3 + label:hover,
input[type="radio"]#vote3:hover + label,
input[type="radio"]#vote3 + label:active,
input[type="radio"]#vote3:active + label {
  background-color: #117788;
}
input[type="radio"]#vote3 + label:focus,
input[type="radio"]#vote3:focus + label:before,
input[type="radio"]#vote3:focus + label,
input[type="radio"]#vote3 + label:focus:before,
input[type="radio"]#vote3 + label:hover,
input[type="radio"]#vote3:hover + label:before,
input[type="radio"]#vote3:hover + label,
input[type="radio"]#vote3 + label:hover:before,
input[type="radio"]#vote3 + label:active,
input[type="radio"]#vote3:active + label:before,
input[type="radio"]#vote3:active + label,
input[type="radio"]#vote3 + label:active:before {
  border-color: #006677;
}
input[type="radio"]#vote3 + label:after {
  background-color: #55bbcc;
}

.voteForm input[type="radio"] + label {
  display: inline-block;
  width: calc(50% - 6px);
  margin-left: 8px;
}

.voteForm input[type="radio"] + label:nth-of-type(3),
.voteForm input[type="radio"] + label:first-of-type {
  margin-left: 0px;
}
*/
input[type="text"] {
  outline: 0;
  padding: 6px;
  border:  transparent solid 2px;
  border-bottom-color: #cccccc;
  transition: border-bottom-color 300ms ease-out;
}
input[type="text"]:focus {
  border-bottom-color: #006677;
}

input[type="text"].pending-status {
  border-bottom-color: #886600;
}

input[type="text"].pending-status:focus {
  border-bottom-color: #ddbb44;
}

input[type="text"][readOnly] {
  border-bottom-color: #3399aa;
  pointer-events: none;
}

.form-elements {
  columns: 1;
  break-inside: avoid;
}
@media (min-width: 768px) {
  .form-elements {
    columns: 2;
  } 
}
.flow-controls {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 12px;
}
@media (min-width: 768px) {
  .flow-controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
  } 
}

.form-view {
  margin-top: 48px;
}

.warning-text {
  display: block;
  font-style: italic;
  color: #4d4c61;
}
.form-group {
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  grid-column-gap: 6px;
  margin-bottom: 24px;
}

.scoring-options {
  display: block;
  margin-bottom: 8px;
}
.scoring-options+.form-group {
  margin-bottom: 36px;
}
.score-label {
  margin-right: 12px;
  margin-left: 12px;
}

#memberForm .form-group:last-child {
  margin-bottom: 58px;
}
.response-label {
    font-size: 1.2rem;
    margin: 0px;
    margin-top: 6px;
}

.tabs {
    margin-top: 48px;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 1fr;
}
.tabs button {
  margin: 0;
  border-radius: 5px;
  z-index: 1;
  border-width: 2px;
}
@media (min-width: 768px) {
  .tabs {
    grid-template-columns: 1fr 1fr 1fr;
  } 
}

.locked {
  color: #9a1c1c;
}

.locked:after {
  content: "\1F512";
}

.unlocked {
  color: #187d18;
}

.unlocked:after {
  content: "\1F513";
}

.isHidden {
  display: none;
}

/* input[type="radio"]:checked + label {
  display: block;
  width: 100%;
  padding: 20px;
} */
